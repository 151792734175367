<template>
  <div class="scan">
    <div class="scan-preview flex-column">
      <!-- <div class="title">{{JSON.stringify(result)}}</div> -->
      <video id="video" ref="video" class="video" controls v-show="false"></video>
      <canvas id="qr-canvas" ref="canvas" class="canvas" v-if="scanable && scanning"></canvas>
      <img src="@/assets/scan.png" class="canvas" v-else/>
      <div class="action flex flex-main-around">
        <button class="button" @click="onCaptureButtonClick">识别</button>
        <button class="button" @click="onChangeButtonClick">切换</button>
        <button class="button" @click="onZoomButtonClick">放缩</button>
      </div>

    </div>
    <!-- <div class="qr-btn" node-type="qr-btn">扫描二维码1
      <input class="camera" ref="camera" type="file" capture="camcorder" accept="video/*" multiple @change="handleClick">
    </div> -->
    <div class="title">{{msg}}</div>
  </div>
</template>
<script>
import Camera from '@/utils/camera'
import jsQR from 'jsqr'
export default {
  props: {
    front: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      constraints: {
        video: {
          width: 120,
          height: 120,
          facingMode: this.front ? 'user' : 'environment'
        }
      },
      result: new Map(),
      support: false,
      scanning: false,
      scaned: false,
      msg: null,
      deviceIndex: 0,
      videoStream: null,
      canvasContext: null,
      zoom: false
    }
  },
  computed: {
      scanable: function () {
          return !!this.videoStream
      }
  },
//   watch: {
//       result: {
//         handler: function (oldV, newV) {
//             alert('1')
//             this.msg = '1'
//             this.result.forEach(r => {
//                 if (this.result.get(r) && this.result.get(r) > 10) {
//                     this.$router.replace({ name: 'setup', params: { sn: this.result.get(r) } })
//                 }
//             })
//         },
//         deep: true
//       }
//   },
  created () {
    this.onStartup()
  },
  mounted () {
  },
  destroyed () {
    this.stopCamera()
  },
  methods: {
    onStartup () {
      // 调用用户媒体设备, 访问摄像头
      Camera.support().then(r => {
        if (r) {
          this.support = true
          this.startCemera()
        } else {
            this.$toast.show('没有摄像设备')
        }
      })
    },
    onChangeButtonClick () {
        if (!this.support) {
            return
        }
      this.stopCamera()
      this.deviceIndex = ++this.deviceIndex % Camera.videoDevices.length
      this.startCemera()
    },
    onZoomButtonClick () {
        if (!this.support) {
            return
        }
        this.zoom = !this.zoom
        // this.stopCamera()
        // this.onCaptureButtonClick()
    },
    onCaptureButtonClick () {
        if (!this.support) {
            return
        }
        this.scanning = true
    //   this.msg = '识别...'
      if (this.$refs.video.readyState === this.$refs.video.HAVE_ENOUGH_DATA) {
          try {
            this.canvasContext = this.$refs.canvas.getContext('2d')
            // this.msg = this.$refs.video.videoWidth + '-' + this.$refs.video.videoHeight + '@' + this.$refs.canvas.width + '-' + this.$refs.canvas.height
            var vt = this.zoom ? this.$refs.video.videoHeight / 4 : 0
            var vl = this.zoom ? this.$refs.video.videoWidth / 4 : 0
            var vw = this.zoom ? this.$refs.video.videoWidth / 2 : this.$refs.video.videoWidth
            var vh = this.zoom ? this.$refs.video.videoHeight / 2 : this.$refs.video.videoHeight
            var ct = 0
            var cl = 0
            var cw = this.$refs.canvas.width
            var ch = this.$refs.canvas.height
            this.canvasContext.drawImage(this.$refs.video, vt, vl, vw, vh, ct, cl, cw, ch)
            var imageData = this.canvasContext.getImageData(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)
            var code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: 'dontInvert' })
            if (code) {
              this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, '#FF3B58')
              this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, '#FF3B58')
              this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, '#FF3B58')
              this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, '#FF3B58')
            //   this.msg = this.result.get(code.data)
                if (this.result.get(code.data)) {
                    var v = this.result.get(code.data) + 1
                    if (v > 10) {
                        this.$router.replace({ name: 'setup', params: { sn: code.data } })
                    }
                    this.result.set(code.data, v)
                } else {
                    this.result.set(code.data, 1)
                }
            }
          } catch (e) {
            this.msg = e
          }
      }
      window.requestAnimationFrame(this.onCaptureButtonClick)
    //   setTimeout(() => that.onCaptureButtonClick(), 1000)
    },
    drawLine (begin, end, color) {
      this.canvasContext.beginPath()
      this.canvasContext.moveTo(begin.x, begin.y)
      this.canvasContext.lineTo(end.x, end.y)
      this.canvasContext.lineWidth = 4
      this.canvasContext.strokeStyle = color
      this.canvasContext.stroke()
    },
    getUserMedia (constraints, success, error) {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = constraints => {
          var getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia
          if (!getUserMedia) {
            return Promise.reject(
              new Error('getUserMedia is not implemented in this browser')
            )
          }
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(success)
        .catch(error)
    },
    success (stream) {
      this.videoStream = stream
      //   var video = document.querySelector('video')
      // Older browsers may not have srcObject
      if ('srcObject' in this.$refs.video) {
        this.$refs.video.srcObject = stream
      } else {
        // Avoid using this in new browsers, as it is going away.
        this.$refs.video.src = window.URL.createObjectURL(stream)
      }
      var that = this
      this.$refs.video.onloadedmetadata = function (e) {
        that.$refs.video.play()
      }
    },
    error (error) {
      console.log(error.name)
      this.$toast.show(`您的浏览器未授权访问摄像头，可更换Chrome再试！(${error.name})`)
    },
    startCemera () {
      var deviceId = Camera.videoDevices[this.deviceIndex].deviceId
      window.console.log(deviceId)
      this.getUserMedia({
          video: {
            width: 500,
            height: 500,
            deviceId: { exact: deviceId }
          }
        }, this.success, this.error)
    },
    stopCamera () {
        if (this.videoStream) {
            this.videoStream.getTracks().forEach(track => {
              track.stop()
            })
        }
    }
  }
}
</script>
<style lang="stylus" scoped>
.scan {
  margin: 100rpx auto;

  .scan-preview {
    width: 500rpx;
    margin: 10rpx auto;

    .title {
      // width 100rpx
    }

    .video {
      width: 500rpx;
      height: 500rpx;
    }

    .canvas {
      width: 500rpx;
      height: 500rpx;
    }

    .action{
        margin-top: 50rpx;
        .button{
            width 120rpx
            height 60rpx
            font-size 32rpx
            border-radius 20rpx
        }
    }
  }
}
</style>
